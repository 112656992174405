import CalendarMaintePage from 'pages/calendarMainte';
import CreateStockRefillReceive from 'pages/createStockRefillReceive';
import HanOtodekesakiPage from 'pages/hanOtodokesakiMainte';
import InputStockRefillOrderPage from 'pages/inputStockRefillOrder';
import LoginPage from 'pages/loginPage';
import ManualPage from 'pages/manual';
import MasterCheckPage from 'pages/masterCheck';
import OsiraseMaintePage from 'pages/osiraseMainte';
import ShippingCorrectionPage from 'pages/shippingCorrection';
import StockInquiryPage from 'pages/stockInquiry';
import SyohinInfoFile from 'pages/syohinInfo';
import SyohinMaintePage from 'pages/syohinMaintePage';
import TantouMaintePage from 'pages/tantouMainte';
import TopPage from 'pages/topPage';
import TorihikiMaintePage from 'pages/torihikiMaintePage';
import WebSearchMenu from 'pages/webSearchMenu';
import ReturnInput from 'views/returnInput';
import CheckOrderBacklog from 'views/webSearchMenu/CheckOrderBacklog';
import CollectingGoodsByProduct from 'views/webSearchMenu/CollectingGoodsByProduct';
import CooperatingStoreInformation from 'views/webSearchMenu/CooperatingStoreInformation';
import HatProductDelivery from 'views/webSearchMenu/HatProductDelivery';
import InventoryInformation from 'views/webSearchMenu/InventoryInformation';
import MonitorDelivery from 'views/webSearchMenu/MonitorDelivery';
import ViewerPDF from 'views/webSearchMenu/ViewerPDF';
import PartnerShipStore from 'views/webSearchMenu/PartnerShipStore';
import PreDelivery from 'views/webSearchMenu/PreDelivery';
import ShipmentTotalByProduct from 'views/webSearchMenu/ShipmentTotalByProduct';
import ShippedLines from 'views/webSearchMenu/ShippedLines/index';
import ShippingDetailsAggregate from 'views/webSearchMenu/ShippingDetailsAggregate/index';
import ShippingDetailsInformation from 'views/webSearchMenu/ShippingDetailsInformation';
import ShippingInformation from 'views/webSearchMenu/ShippingInformation';
import ShippingInformationForReport from 'views/webSearchMenu/ShippingInformationForReport';
import StockDetails from 'views/webSearchMenu/StockDetails';
import StockInformationProducts from 'views/webSearchMenu/StockInformationProducts';
import VehicleAllocationList from 'views/webSearchMenu/VehicleAllocationList';
import VoucherCollectionChecklist from 'views/webSearchMenu/VoucherCollectionChecklist';
import InventoryList from 'pages/inventoryList';
import BacklogDeletionPage from 'pages/BacklogDelete';
import OrderProcessingCheck from 'views/webSearchMenu/OrderProcessingCheck';
import SlipCollectionChecklist from 'views/webSearchMenu/SlipCollectionChecklist';
import HacobuOutput from 'views/webSearchMenu/HacobuOutput';

export interface Paths {
  [key: string]: string;
}
export const PATH: Paths = Object.freeze({
  LOGIN: '/',
  TOP: '/Menu',
  YHK_MANUAL: '/YHKManual',
  CALENDAR_MAINTE: '/CalendarMainte',
  TORIHIKISAKI_MAINTE: '/TorihikisakiMainte',
  HANOTODOKESAKI_MAINTE: '/HanOtodokesakiMainte',
  TANTOU_MAINTE: '/TantouMainte',
  OSIRASE_MAINTE: '/OsiraseMainte',
  SYOHIN_MAINTE: '/SyohinMainte',
  MASTER_CHECK: '/MasterCheck',
  SYOHIN_INFO: '/SyohinInfo',
  WEBSEARCH_MENU: '/WebSearchMenu',
  HATPRODUCTDELIVERY: '/WebSearchMenu/HatProductDelivery',
  MONITORDELIVERY: '/WebSearchMenu/MonitorDelivery',
  MONITORDELIVERYBYFLIGHT: '/WebSearchMenu/MonitorDeliveryByFlight',
  MONITORDELIVERYBYFLIGHTPDF: '/WebSearchMenu/MonitorDeliveryByFlightPDF',
  SHIPPING_CORRECTION: '/ShippingCorrection',
  RETURN_INPUT: '/ReturnInput',
  CREATE_STOCKREFILLRECEIVE: '/CreateStockRefillReceive',
  BACKLOG_DELETION: '/BacklogDeletion',
  STOCKDETAILS: '/WebSearchMenu/StockDetails',
  VEHICLEALLOCATIONLIST: '/WebSearchMenu/VehicleAllocationList',
  COLLECTINGGOODSBYPRODUCT: '/WebSearchMenu/CollectingGoodsByProduct',
  COOPERATINGSTOREINFORMATION: '/WebSearchMenu/CooperatingStoreInformation',
  SHIPMENTTOTALBYPRODUCT: '/WebSearchMenu/ShipmentTotalByProduct',
  SHIPPEDLINES: '/WebSearchMenu/ShippedLines',
  SHIPPINGDETAILSAGGREGATE: '/WebSearchMenu/ShippingDetailsAggregate',
  INPUT_STOCKREFILLORDER: '/InputStockRefillOrder',
  STOCK_INQUIRY: '/StockInquiry',
  SHIPPINGINFORMATION: '/WebSearchMenu/ShippingInformation',
  STOCKINFORMATIONPRODUCTS: '/WebSearchMenu/StockInformationProducts',
  INVENTORYINFORMATION: '/WebSearchMenu/InventoryInformation',
  PARTNERSHIPSTORE: '/WebSearchMenu/PartnerShipStore',
  INVENTORYLIST: '/InventoryList',
  SHIPPINGDETAILSINFORMATION: '/WebSearchMenu/ShippingDetailsInformation',
  CONFIRMLISTVOUCHER: '/WebSearchMenu/ConfirmListVoucher',
  LISTREVOKEDVOUCHERS: '/WebSearchMenu/ListRevokedVouchers',
  LISTREVOKEDVOUCHERSPDF: '/WebSearchMenu/ListRevokedVouchersPDF',
  SHIPPINGINFORMATIONFORREPORT: '/WebSearchMenu/ShippingInformationForReport',
  HACOBUOUTPUT: '/WebSearchMenu/HacobuOutput',
  PREDELIVERY: '/WebSearchMenu/PreDelivery',
  FLIGHTDISPATCHLIST: '/WebSearchMenu/FlightDispatchList',
  VOUCHERCOLLECTIONCHECKLIST: '/WebSearchMenu/VoucherCollectionChecklist',
  CHECKORDERBACKLOG: '/WebSearchMenu/CheckOrderBacklog',
  ORDERPROCESSINGCHECK: '/WebSearchMenu/OrderProcessingCheck',
  SLIPCOLLECTIONCHECKLIST: '/WebSearchMenu/SlipCollectionChecklist',
  FLIGHTDISPATCHLISTPDF: '/WebSearchMenu/FlightDispatchListPDF',
  CONFIRMLISTVOUCHERPDF: '/WebSearchMenu/ConfirmListVoucherPDF',
});

export const PERMITTED_ROLES = Object.freeze({
  ALL: [],
});

export const ROUTES = Object.freeze({
  PRIVATE: [
    {
      path: PATH.TOP,
      element: <TopPage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.CALENDAR_MAINTE,
      element: <CalendarMaintePage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.TORIHIKISAKI_MAINTE,
      element: <TorihikiMaintePage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.SYOHIN_MAINTE,
      element: <SyohinMaintePage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.TANTOU_MAINTE,
      element: <TantouMaintePage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.HANOTODOKESAKI_MAINTE,
      element: <HanOtodekesakiPage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.OSIRASE_MAINTE,
      element: <OsiraseMaintePage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.MASTER_CHECK,
      element: <MasterCheckPage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.WEBSEARCH_MENU,
      element: <WebSearchMenu />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.HATPRODUCTDELIVERY,
      element: <HatProductDelivery />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.MONITORDELIVERY,
      element: <MonitorDelivery />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.SHIPPING_CORRECTION,
      element: <ShippingCorrectionPage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.RETURN_INPUT,
      element: <ReturnInput></ReturnInput>,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.CREATE_STOCKREFILLRECEIVE,
      element: <CreateStockRefillReceive />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.BACKLOG_DELETION,
      element: <BacklogDeletionPage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.STOCKDETAILS,
      element: <StockDetails />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.VEHICLEALLOCATIONLIST,
      element: <VehicleAllocationList />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.COLLECTINGGOODSBYPRODUCT,
      element: <CollectingGoodsByProduct />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.COOPERATINGSTOREINFORMATION,
      element: <CooperatingStoreInformation />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.SHIPMENTTOTALBYPRODUCT,
      element: <ShipmentTotalByProduct />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.SHIPPEDLINES,
      element: <ShippedLines />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.SHIPPINGDETAILSAGGREGATE,
      element: <ShippingDetailsAggregate />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.INPUT_STOCKREFILLORDER,
      element: <InputStockRefillOrderPage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.STOCK_INQUIRY,
      element: <StockInquiryPage />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.STOCKINFORMATIONPRODUCTS,
      element: <StockInformationProducts />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.INVENTORYINFORMATION,
      element: <InventoryInformation />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.PARTNERSHIPSTORE,
      element: <PartnerShipStore />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.SHIPPINGINFORMATION,
      element: <ShippingInformation />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.INVENTORYLIST,
      element: <InventoryList />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.SHIPPINGDETAILSINFORMATION,
      element: <ShippingDetailsInformation />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.SHIPPINGINFORMATIONFORREPORT,
      element: <ShippingInformationForReport />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.HACOBUOUTPUT,
      element: <HacobuOutput />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.PREDELIVERY,
      element: <PreDelivery />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.VOUCHERCOLLECTIONCHECKLIST,
      element: <VoucherCollectionChecklist />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.CHECKORDERBACKLOG,
      element: <CheckOrderBacklog />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.ORDERPROCESSINGCHECK,
      element: <OrderProcessingCheck />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
    {
      path: PATH.SLIPCOLLECTIONCHECKLIST,
      element: <SlipCollectionChecklist />,
      permittedRoles: PERMITTED_ROLES.ALL,
    },
  ],
  PUBLIC: [
    { path: PATH.LOGIN, element: <LoginPage /> },
    { path: PATH.SYOHIN_INFO, element: <SyohinInfoFile /> },
    {
      path: PATH.YHK_MANUAL,
      element: <ManualPage />,
    },
    {
      path: PATH.LISTREVOKEDVOUCHERSPDF,
      element: <ViewerPDF />,
    },
    {
      path: PATH.MONITORDELIVERYBYFLIGHTPDF,
      element: <ViewerPDF />,
    },
    {
      path: PATH.FLIGHTDISPATCHLISTPDF,
      element: <ViewerPDF />,
    },
    {
      path: PATH.CONFIRMLISTVOUCHERPDF,
      element: <ViewerPDF />,
    },
  ],
});
