import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  nohinDt: string | null;
}

export function useGetHacobuOutputExcel() {
  const getShipping = async ({ nohinDt }: TypeProps) => {
    const params = {
      nohinDt,
    };
    return await AxiosGetFile(API_URL.GET_HACOBUOUTPUT_EXCEL, params);
  };

  const mutation = useMutation(getShipping as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
